import React from "react"
import classNames from "classnames"

import { Text } from "../../components/Core"
import { ButtonGroupMap } from "../../components/Button"

function LearnMoreCTA(props) {
  const wrapperClassNames = classNames(
    "body-sections",
    "section",
    "learn-more-section",
    {
      "color-back": props.colorBack
    }
  )

  return (
    <section className={wrapperClassNames}>
      <div className="columns has-text-centered-tablet">
        <div className="column is-5" />
        <div className="column">
          <Text
            as="h3"
            style={{ marginBottom: "1.5rem" }}
            text={props.post.learnMore.heading}
          />
        </div>
        <div className="column is-5" />
      </div>
      <div className="columns has-text-centered-tablet">
        <div className="column is-4" />
        <div className="column mobile-col">
          <Text text={props.post.learnMore.blurb} />
        </div>
        <div className="column is-4" />
      </div>

      <div className="columns">
        <div className="column">
          <ButtonGroupMap
            isCentered
            noReverse
            buttons={props.post.learnMore.buttons}
          />
        </div>
      </div>
    </section>
  )
}

export default LearnMoreCTA
